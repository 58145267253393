import React from 'react';
import Select from "react-select";
import { option } from 'yargs';
import './App.css';

const optionAmerica = {
  value: "America",
  label: "America"
}

function App() {

  const options = [
    optionAmerica
  ]

  const [selectedValue, setSelectedValue] = React.useState(optionAmerica)

  const handleSelectedValue = (e:any) => {

    setSelectedValue(e)

  }

  return (
    <div className="App">

        <div className="selectContainer">
          
          <Select
            className="countrySelect"
            placeholder="Country..."
            options={options}
            value={selectedValue}
            onChange={handleSelectedValue}
          />

        </div>

        <div className="headerText">

          Is The President Being Impeached?

        </div>

        <div className="mainText">

          NO*

        </div>

        <div className="secondaryText">

          Days since last impeachment: 0*

        </div>

        <div className="noteText">

          * A former president is being impeached ¯\_(ツ)_/¯

        </div>

    </div>
  );
}

export default App;
